.sidebar-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.profile-setting-img{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width: 768px) { 
    .sidebar-img{
        width: 30px;
        height: 30px;
    }
    .profile-setting-img{
        width: 70px;
        height: 70px;
    }
}