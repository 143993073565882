/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {
    /* Facebook-style scrollbar for search suggestions */
    .search-scrollbar::-webkit-scrollbar {
      width: 4px;
    }
  
    .search-scrollbar::-webkit-scrollbar-track {
      background: transparent;
    }
  
    .search-scrollbar::-webkit-scrollbar-thumb {
      background: #787f87; /* Light gray (Tailwind slate-300) */
      border-radius: 20px;
    }
  
    .search-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #5f6874; /* Darker gray (Tailwind slate-400) */
    }
  }